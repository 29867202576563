

import React, {Component, useContext} from 'react';
import {LanguageContext} from './LanguageProvider';

import ReactMarkdown from 'react-markdown'






export default function Footer(){


    let data = require('../data/cmbPages.json')[0];



    const context = useContext(LanguageContext)

    let x =(
        <footer>
            <dl>
                <dt>{data.footer[context].title}</dt>
                    <dd>
                        <ReactMarkdown components={{p:"address"}}>{"Trg braće Mažuranića 10  \n  51000  \n  Rijeka"}</ReactMarkdown>
                       
                        </dd>
                <dd>cmb@uniri.hr</dd>
            </dl>
           
        </footer>
    )

    return x



}