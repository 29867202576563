import React from 'react'



export function LocationMarker({className}){


return(<svg className={className} width="34.243mm" height="48.225mm" version="1.1" viewBox="0 0 34.243 48.225" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(-8.4663 -43.192)">
  <g id="g1391" stroke="#0d3b66" stroke-width="3.5">
   <path id="path853" transform="scale(.26458)" d="m96.709 172.7a55.263 55.263 0 0 0-55.262 55.262 55.263 55.263 0 0 0 17.67 40.508l38.295 67.602 40.223-71.004a55.263 55.263 0 0 0 11.965-21.123l0.0723-0.12695h-0.0391a55.263 55.263 0 0 0 2.3398-15.855 55.263 55.263 0 0 0-55.264-55.262zm2e-3 25.9a25.785 25.785 0 0 1 25.785 25.785 25.785 25.785 0 0 1-25.785 25.785 25.785 25.785 0 0 1-25.787-25.785 25.785 25.785 0 0 1 25.787-25.785z" fill="none" stroke="#0d3b66" stroke-linecap="round" stroke-linejoin="round" stroke-width="13.228"/>
  </g>
 </g>
</svg>
)
}


export function LanguageMarker({className}){
 return(   <svg className={className} width="113.48mm" height="85.491mm" version="1.1" viewBox="0 0 113.48 85.491" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(-91.9 -125)" fill="none" stroke="#0d3b66" stroke-linecap="round">
  <path id="rect336248" d="m113.16 129c-9.5618 0-17.26 5.9055-17.26 13.241v33.263c0 6.0033 5.1575 11.046 12.271 12.681 0.23854 1.4741 0.51885 3.0769 0.85483 4.9578 2.9878 16.727 2.9886 16.726 18.377 5.309 6.5991-4.8963 10.357-7.6908 11.061-9.7068h45.657c9.5618 0 17.26-5.9055 17.26-13.241v-33.263c0-7.3355-7.6982-13.241-17.26-13.241z" stroke-width="8"/>
  <g stroke-width="4">
   <path id="path342497" d="m112.26 147.6h43.457"/>
   <path id="path342497-2" d="m110.82 173.08h73.156"/>
   <path id="path342497-2-6" d="m111.21 160.34h73.156"/>
  </g>
 </g>
</svg>
 )
}


export function TimeMarker({className}){

  return(  <svg className={className} width="81.806mm" height="81.806mm" version="1.1" viewBox="0 0 81.806 81.806" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(-36.088 -41.115)" fill="none" stroke="#0d3b66" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5">
  <circle id="path1238" cx="76.992" cy="82.018" r="38.153"/>
  <path id="path1317" d="m76.992 82.018 0.22846-27.644"/>
  <path id="path1317-2" d="m104.3 77.691-27.304 4.3265"/>
 </g>
</svg>)
}


export function Calendar({className}){
 
 
 return(<svg className={className} width="114.18mm" height="114.62mm" version="1.1" viewBox="0 0 114.18 114.62" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(.8499 -27.308)" stroke="#0d3b66" stroke-linecap="round">
  <rect id="rect336248" x="6.4208" y="50.421" width="98.928" height="73.041" rx="4.0469" ry="4.0469" fill="none" stroke-width="7.6769"/>
  <g fill="#0d3b66" stroke-width="2.407">
   <rect id="rect336248-9" x="14.877" y="76.208" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2" x="36.789" y="76.208" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2-1" x="80.615" y="76.208" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2-1-6" x="58.702" y="76.208" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-8" x="14.877" y="97.413" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2-2" x="36.789" y="97.413" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2-1-5" x="80.615" y="97.413" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
   <rect id="rect336248-9-2-1-6-8" x="58.702" y="97.413" width="14.78" height="14.78" rx=".60463" ry=".81893"/>
  </g>
  <g fill="none" stroke-width="7.6769">
   <path id="path336495" d="m26.967 50.382v-9.7441"/>
   <path id="path336495-5" d="m89.226 49.789v-9.7441"/>
   <path id="path342497" d="m19.779 65.527h36.573"/>
  </g>
 </g>
</svg>)
}


export function Burger({className}){
 return( <svg className={className} width="20mm" height="13.216mm" version="1.1" viewBox="0 0 20 13.216" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(-20.028 -45.001)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2.2297">
  <path id="path864" d="m21.143 46.116h17.77"/>
  <path id="path864-5" d="m21.143 51.609h17.77"/>
  <path id="path864-2" d="m21.143 57.102h17.77"/>
 </g>
</svg>
 )
}

export function SteepArrow({className}){
 return(<svg className={className} width="10.779mm" height="33.387mm" version="1.1" viewBox="0 0 10.779 33.387" xmlns="http://www.w3.org/2000/svg">
 <g id="layer1" transform="translate(-51.228 16.807)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2.2297">
  <path id="path864" d="m52.343-0.1141 8.5496-15.578"/>
  <path id="path864-0" d="m52.343-0.1141 8.5496 15.578"/>
  <path id="path864-5" d="m21.143 51.609h17.77"/>
  <path id="path864-2" d="m21.143 57.102h17.77"/>
 </g>
</svg>
 )
}


export function Logo({className}){



  return(
    <svg className={className} width="242.33" height="273.67" version="1.0" viewBox="0 0 181.75 205.25" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify">
 <defs id="defs11950">
  <clipPath id="id1">
   <path id="path11944" d="m102 108h136.23v137h-136.23z"/>
  </clipPath>
 </defs>
 <g fill="#0d3b66">
  <g id="g11964" transform="translate(-100.56 -87.311)">
   <g id="g11962" transform="translate(105.66 133.35)">
    <g id="g11960">
     <path id="path11958" d="m3.8789-4.3047c1.2773-1.4805 1.6172-3.3555 0.85938-5.0312l-1.1133 0.57422c0.53516 1.2539 0.26562 2.6016-0.65625 3.6719-1.3398 1.5586-3.4648 1.8711-5.3477 0.25391-1.8672-1.6094-1.875-3.7578-0.53516-5.3164 0.92187-1.0703 2.2188-1.5312 3.5391-1.1914l0.39453-1.1914c-1.7617-0.49609-3.5703 0.11719-4.8477 1.5977-1.8203 2.1172-1.7578 4.9883 0.63281 7.0469 2.4062 2.0703 5.2539 1.7031 7.0742-0.41406z"/>
    </g>
   </g>
  </g>
  <g id="g11972" transform="translate(-100.56 -87.311)">
   <g id="g11970" transform="translate(116.48 121.59)">
    <g id="g11968">
     <path id="path11966" d="m-0.44141-11.113-0.74219-0.875-4.043 3.4375 6.4102 7.543 4.0469-3.4375-0.74219-0.87109-3.0664 2.6016-2.0938-2.4609 2.6992-2.2969-0.73438-0.86328-2.6992 2.2969-2.0977-2.4688z"/>
    </g>
   </g>
  </g>
  <g id="g11980" transform="translate(-100.56 -87.311)">
   <g id="g11978" transform="translate(126.52 113.42)">
    <g id="g11976">
     <path id="path11974" d="m1.8516-12.723 4.1523 6.8047-8.707-4.0273-1.125 0.68359 5.1562 8.4531 1.0547-0.64453-4.168-6.8359 8.7266 4.0586 1.1328-0.69141-5.1523-8.4531z"/>
    </g>
   </g>
  </g>
  <g id="g11988" transform="translate(-100.56 -87.311)">
   <g id="g11986" transform="translate(140.65 105.45)">
    <g id="g11984">
     <path id="path11982" d="m2.418-11.723-5.8516 2.4297 0.44141 1.0586 2.3477-0.97656 3.3594 8.082 1.1562-0.47656-3.3594-8.0859 2.3438-0.97266z"/>
    </g>
   </g>
  </g>
  <g id="g11996" transform="translate(-100.56 -87.311)">
   <g id="g11994" transform="translate(152.21 100.98)">
    <g id="g11992">
     <path id="path11990" d="m8.1211-2.0664-3.3711-3.25c0.46094-0.23828 0.80469-0.53906 1.0547-0.89062 0.52734-0.67188 0.63672-1.5781 0.375-2.6133-0.26563-1.0352-0.79297-1.6836-1.5938-1.9531-0.65625-0.24609-1.4922-0.23047-2.5703 0.04688l-2.9492 0.75 2.4414 9.5938 1.2188-0.3125-0.94922-3.7266 1.7305-0.4375 0.082032-0.023437 3.1094 3.1797zm-7.5664-7.1641 1.5742-0.40234c0.83594-0.21094 1.4648-0.27734 1.9609-0.046876 0.38672 0.18359 0.6875 0.57031 0.85547 1.2227 0.17188 0.68359 0.11328 1.1836-0.125 1.5703-0.28516 0.49609-0.87891 0.78516-1.7383 1.0039l-1.5742 0.39844z"/>
    </g>
   </g>
  </g>
  <g id="g12004" transform="translate(-100.56 -87.311)">
   <g id="g12002" transform="translate(166.28 97.858)">
    <g id="g12000">
     <path id="path11998" d="m5.9375-9.4062-0.11328-1.1406-5.2812 0.53906 1.0039 9.8516 5.2812-0.53906-0.11719-1.1406-4 0.40625-0.32813-3.2148 3.5234-0.35938-0.11328-1.1289-3.5273 0.35937-0.32812-3.2227z"/>
    </g>
   </g>
  </g>
  <g id="g12012" transform="translate(-100.56 -87.311)">
   <g id="g12010" transform="translate(188.48 97.344)">
    <g id="g12008">
     <path id="path12006" d="m8.0352-7.7305 0.15625-1.1367-5.3008-0.73047-1.3516 9.8086 1.2734 0.17578 0.59766-4.3398 3.5117 0.48438 0.15625-1.1289-3.5117-0.48438 0.44141-3.2031z"/>
    </g>
   </g>
  </g>
  <g id="g12020" transform="translate(-100.56 -87.311)">
   <g id="g12018" transform="translate(201.02 99.32)">
    <g id="g12016">
     <path id="path12014" d="m5.5039 1.8125c2.6445 0.80469 5.2617-0.40234 6.1836-3.4375 0.91797-3.0195-0.58203-5.4844-3.2227-6.2891-2.6719-0.8125-5.2773 0.40234-6.1953 3.4219-0.92188 3.0352 0.5625 5.4922 3.2344 6.3047zm0.34766-1.1367c-1.9648-0.59766-3.1094-2.4141-2.3828-4.8008 0.71875-2.3633 2.6797-3.2344 4.6445-2.6367 1.9492 0.59375 3.0898 2.4102 2.3711 4.7695-0.72656 2.3867-2.6836 3.2617-4.6328 2.668z"/>
    </g>
   </g>
  </g>
  <g id="g12028" transform="translate(-100.56 -87.311)">
   <g id="g12026" transform="translate(216.86 104.7)">
    <g id="g12024">
     <path id="path12022" d="m7.5 3.7383-0.43359-4.6602c0.50781 0.11719 0.96484 0.11719 1.3867 0.011719 0.83594-0.16406 1.5117-0.77734 1.9883-1.7344 0.47656-0.95312 0.5-1.793 0.06641-2.5156-0.33594-0.61719-0.98047-1.1523-1.9766-1.6484l-2.7227-1.3594-4.418 8.8633 1.125 0.55859 1.7188-3.4414 1.5977 0.79688 0.074219 0.039063 0.27734 4.4375zm-1.0508-10.367 1.4531 0.72266c0.77344 0.38672 1.293 0.75 1.5156 1.2461 0.17578 0.39062 0.14844 0.88281-0.15234 1.4883-0.3125 0.62891-0.6875 0.96875-1.1172 1.1055-0.53906 0.1875-1.1797 0.019531-1.9727-0.37891l-1.4531-0.72266z"/>
    </g>
   </g>
  </g>
  <g id="g12036" transform="translate(-100.56 -87.311)">
   <g id="g12034" transform="translate(237.07 116.67)">
    <g id="g12032">
     <path id="path12030" d="m8.8984 8.0859 6.1055-7.8281-1.2383-1.125-7.418 3.6914 2.9688-7.7344-1.2266-1.1172-7.2148 6.8203 0.91016 0.82812 5.9688-5.5742-3.1484 8.1367 0.55469 0.50781 7.8398-3.918-5.0078 6.4883z"/>
    </g>
   </g>
  </g>
  <g id="g12044" transform="translate(-100.56 -87.311)">
   <g id="g12042" transform="translate(250.2 129.59)">
    <g id="g12040">
     <path id="path12038" d="m0.98047 1.207 0.79297 0.97656 7.6836-6.2422-0.79297-0.97656z"/>
    </g>
   </g>
  </g>
  <g id="g12052" transform="translate(-100.56 -87.311)">
   <g id="g12050" transform="translate(256.19 137.18)">
    <g id="g12048">
     <path id="path12046" d="m12.715 1.918-6.8281 4.1172 4.0742-8.6875-0.67969-1.125-8.4805 5.1094 0.64062 1.0586 6.8594-4.1328-4.1055 8.7031 0.6875 1.1406 8.4766-5.1094z"/>
    </g>
   </g>
  </g>
  <g id="g12060" transform="translate(-100.56 -87.311)">
   <g id="g12058" transform="translate(264.13 151.26)">
    <g id="g12056">
     <path id="path12054" d="m0.56641 1.4492 1.1602 2.9727c1.1016 2.8164 3.5781 3.5078 6.2812 2.4492 2.6914-1.0508 4.043-3.2383 2.9414-6.0547l-1.1641-2.9727zm1.5234 0.75391 7.0859-2.7695 0.69141 1.7617c0.8125 2.082-0.19922 3.625-2.3359 4.4609-2.1523 0.83984-3.9375 0.39062-4.75-1.6914z"/>
    </g>
   </g>
  </g>
  <g id="g12068" transform="translate(-100.56 -87.311)">
   <g id="g12066" transform="translate(271.26 174.78)">
    <g id="g12064">
     <path id="path12062" d="m0.87109 7.8633 0.14453 1.3047 9.4375-4.7188-0.16406-1.4922-10.242-2.5391 0.14453 1.3086 2.4492 0.57813 0.49219 4.4492zm2.918-5.2852 5.3242 1.2656-4.918 2.3984z"/>
    </g>
   </g>
  </g>
  <g id="g12076" transform="translate(-100.56 -87.311)">
   <g id="g12074" transform="translate(272.51 189.54)">
    <g id="g12072">
     <path id="path12070" d="m9.4219 8.75-7.9609-0.44922 8.2617-4.8789 0.074219-1.3125-9.8828-0.55859-0.070312 1.2344 7.9961 0.45312-8.2969 4.875-0.078125 1.3281 9.8867 0.55859z"/>
    </g>
   </g>
  </g>
  <g id="g12084" transform="translate(-100.56 -87.311)">
   <g id="g12082" transform="translate(271.13 205.64)">
    <g id="g12080">
     <path id="path12078" d="m-0.34766 1.5156-0.71484 3.1094c-0.67969 2.9453 0.97266 4.9141 3.8047 5.5664 2.8164 0.64844 5.1641-0.39844 5.8398-3.3477l0.71875-3.1094zm0.83203 1.4805 7.418 1.707-0.42578 1.8438c-0.5 2.1797-2.207 2.8828-4.4414 2.3711-2.2539-0.51953-3.4727-1.8984-2.9727-4.0781z"/>
    </g>
   </g>
  </g>
  <g id="g12092" transform="translate(-100.56 -87.311)">
   <g id="g12090" transform="translate(263.74 229.1)">
    <g id="g12088">
     <path id="path12086" d="m1.6562 7.9375c-0.03125 0.88281 0.45312 1.6328 1.293 2.0742 1.3711 0.71875 2.8633 0.19531 3.6836-1.3672l1.4023-2.6562-8.7578-4.6133-1.7969 3.4102c-0.79688 1.5117-0.32422 2.9922 1.0703 3.7266 1.207 0.63672 2.3008 0.32422 3.1055-0.57422zm1.7773 0.99219c-0.79688-0.41797-1.1641-1.3047-0.74219-2.1055l0.9375-1.7812 2.8633 1.5078-0.83203 1.5742c-0.51172 0.96875-1.4297 1.2266-2.2266 0.80469zm-4.8672-3.8125 1.1328-2.1445 3.0078 1.5859-1.0859 2.0664c-0.55469 1.0508-1.5352 1.3125-2.3867 0.86328-0.80859-0.42578-1.1953-1.3672-0.66797-2.3711z"/>
    </g>
   </g>
  </g>
  <g id="g12100" transform="translate(-100.56 -87.311)">
   <g id="g12098" transform="translate(256.72 241.5)">
    <g id="g12096">
     <path id="path12094" d="m3.0117 10.707 0.92578 0.67578 3.1367-4.2812-7.9922-5.8477-3.1328 4.2852 0.92188 0.67578 2.375-3.2461 2.6133 1.9102-2.0938 2.8594 0.91406 0.66797 2.0938-2.8594 2.6133 1.9141z"/>
    </g>
   </g>
  </g>
  <g id="g12108" transform="translate(-100.56 -87.311)">
   <g id="g12106" transform="translate(248.84 251.77)">
    <g id="g12104">
     <path id="path12102" d="m-1.1016 1.0977-0.89453 0.89453 3.1016 3.1094-3.5469 3.543-3.1055-3.1133-0.89062 0.88672 6.9922 7.0117 0.89062-0.89063-3.0898-3.0938 3.5508-3.543 3.0898 3.0977 0.89453-0.89453z"/>
    </g>
   </g>
  </g>
  <g id="g12116" transform="translate(-100.56 -87.311)">
   <g id="g12114" transform="translate(237.32 262.6)">
    <g id="g12112">
     <path id="path12110" d="m-6.4414 4.5898-1.0703 0.76172 8.7148 5.9453 1.2266-0.87109-2.7734-10.18-1.0703 0.76172 0.6875 2.418-3.6484 2.6016zm6.0312-0.023438 1.4922 5.2656-4.4922-3.1289z"/>
    </g>
   </g>
  </g>
  <g id="g12124" transform="translate(-100.56 -87.311)">
   <g id="g12122" transform="translate(224.99 270.87)">
    <g id="g12120">
     <path id="path12118" d="m-2.4492 12.266-1.0586-8.7656 6.3438 6.1445 1.1875-0.58984-7.6523-7.2539-1.1562 0.57422 1.1484 10.48z"/>
    </g>
   </g>
  </g>
  <g id="g12132" transform="translate(-100.56 -87.311)">
   <g id="g12130" transform="translate(211.59 276.94)">
    <g id="g12128">
     <path id="path12126" d="m-1.4688 0.50391-1.1914 0.40625 3.1953 9.3672 1.1914-0.40625z"/>
    </g>
   </g>
  </g>
  <g id="g12140" transform="translate(-100.56 -87.311)">
   <g id="g12138" transform="translate(202.4 279.94)">
    <g id="g12136">
     <path id="path12134" d="m-5.7188 0.94141c-2.7148 0.51172-4.4727 2.7969-3.8867 5.9141 0.58594 3.1016 3.0547 4.5898 5.7695 4.0781 2.7422-0.51953 4.4883-2.8008 3.9023-5.9023-0.58594-3.1172-3.043-4.6055-5.7852-4.0898zm0.21875 1.168c2.0195-0.37891 3.875 0.69922 4.3398 3.1523 0.45703 2.4258-0.87891 4.1094-2.8984 4.4883-2.0039 0.37891-3.8516-0.70312-4.3086-3.1289-0.46484-2.4531 0.86328-4.1328 2.8672-4.5117z"/>
    </g>
   </g>
  </g>
  <g id="g12148" transform="translate(-100.56 -87.311)">
   <g id="g12146" transform="translate(185.89 282.54)">
    <g id="g12144">
     <path id="path12142" d="m-4.9453-0.058594c-2.0469 0.03125-3.5938 1.293-3.5625 3.4727l0.10156 6.6133 1.2578-0.01953-0.097656-6.4375c-0.023437-1.6445 1.0039-2.4648 2.3203-2.4844 1.3125-0.019532 2.3438 0.75391 2.3711 2.4141l0.097657 6.4375 1.2578-0.019531-0.10156-6.6133c-0.03125-2.1797-1.6133-3.3945-3.6445-3.3633z"/>
    </g>
   </g>
  </g>
  <g id="g12156" transform="translate(-100.56 -87.311)">
   <g id="g12154" transform="translate(170.79 282.33)">
    <g id="g12152">
     <path id="path12150" d="m-8.2969-1.1992 1.875 4.293c-0.51953 0.046875-0.94922 0.1875-1.3164 0.41797-0.74609 0.42188-1.1914 1.2148-1.3477 2.2734-0.15234 1.0586 0.089844 1.8594 0.72656 2.4141 0.51172 0.47656 1.293 0.78125 2.3906 0.94141l3.0156 0.43359 1.4141-9.7969-1.2461-0.17969-0.54688 3.8047-1.7695-0.25391-0.082032-0.011719-1.6562-4.125zm4.2539 9.5156-1.6094-0.23047c-0.85547-0.125-1.4609-0.30469-1.8281-0.70703-0.28906-0.31641-0.41797-0.79297-0.32422-1.4609 0.10156-0.69531 0.35156-1.1328 0.71484-1.4023 0.45703-0.34375 1.1133-0.38672 1.9922-0.25781l1.6055 0.23047z"/>
    </g>
   </g>
  </g>
 </g>
 <g id="g12160" transform="translate(-100.56 -87.311)" clip-path="url(#id1)">
  <path id="path12158" d="m238.25 131.45c-31.164-31.266-81.684-31.266-112.85 0-31.16 31.266-31.16 81.957 0 113.22l112.85-113.22v-4e-3" fill="#0d3b66"/>
 </g>
 <g fill="#0d3b66">
  <path id="path12162" d="m97.871 117.42-0.57031-0.57031c-2.082 2.0898-4.5078 3.7305-7.2109 4.8789-2.7969 1.1836-5.7656 1.7891-8.832 1.7891-3.0625 0-6.0352-0.60546-8.832-1.7891-2.6992-1.1484-5.125-2.7891-7.207-4.8789l-0.57031 0.57031c4.2539 4.2656 10.125 6.9023 16.609 6.9023 6.4883 0 12.359-2.6367 16.613-6.9023"/>
  <path id="path12164" d="m95.215 114.76-0.5664-0.57032c-1.7422 1.7461-3.7656 3.1133-6.0195 4.0703-2.332 0.99218-4.8125 1.4922-7.3711 1.4922-2.5547 0-5.0352-0.5-7.3672-1.4922-2.2578-0.95704-4.2812-2.3242-6.0195-4.0703l-0.5664 0.57032c3.5703 3.582 8.5039 5.8008 13.953 5.8008 5.4531 0 10.387-2.2188 13.957-5.8008"/>
  <path id="path12166" d="m92.562 112.09-0.57031-0.57031c-1.3945 1.3984-3.0156 2.4961-4.8242 3.2656-1.8711 0.79296-3.8594 1.1953-5.9102 1.1953-2.0469 0-4.0391-0.40235-5.9062-1.1953-1.8086-0.76563-3.4336-1.8672-4.8242-3.2656l-0.57031 0.57422c2.8945 2.8984 6.8867 4.6953 11.301 4.6953s8.4102-1.7969 11.305-4.6992"/>
  <path id="path12168" d="m100.52 120.08-0.56641-0.57031c-2.4297 2.4375-5.2578 4.3516-8.4062 5.6875-3.2578 1.3828-6.7227 2.0859-10.293 2.0859-3.5664 0-7.0312-0.70313-10.289-2.0859-3.1484-1.3359-5.9766-3.25-8.4062-5.6875l-0.56641 0.57422c4.9297 4.9453 11.738 8.0039 19.262 8.0039s14.336-3.0586 19.266-8.0078"/>
  <path id="path12170" d="m89.906 109.43-0.56641-0.57032c-1.0508 1.0508-2.2734 1.8789-3.6328 2.457-1.4062 0.59375-2.9023 0.89843-4.4492 0.89843-1.543 0-3.0391-0.30468-4.4453-0.89843-1.3594-0.57813-2.5859-1.4062-3.6328-2.457l-0.56641 0.57032c2.2109 2.2188 5.2695 3.5938 8.6445 3.5938 3.3789 0 6.4375-1.375 8.6484-3.5938"/>
  <path id="path12176" d="m84.598 104.1-0.56641-0.57031c-0.35938 0.36328-0.77734 0.64453-1.2461 0.84375-0.48047 0.20312-0.9961 0.30859-1.5274 0.30859-0.52734 0-1.043-0.10547-1.5234-0.30859-0.46875-0.19922-0.88671-0.48047-1.2461-0.84375l-0.56641 0.57422c0.85157 0.85547 2.0312 1.3828 3.3359 1.3828s2.4883-0.52734 3.3398-1.3867"/>
  <path id="path12178" d="m105.83 125.41-0.56641-0.57031c-3.1211 3.1289-6.75 5.582-10.793 7.2969-4.1836 1.7774-8.6289 2.6797-13.215 2.6797-4.582 0-9.0273-0.90234-13.211-2.6797-4.043-1.7148-7.6758-4.168-10.793-7.2969l-0.56641 0.57031c6.2891 6.3086 14.977 10.211 24.57 10.211 9.5977 0 18.285-3.9024 24.574-10.211"/>
  <path id="path12180" d="m87.254 106.77-0.57032-0.57031c-0.70312 0.70703-1.5234 1.2617-2.4375 1.6484-0.94531 0.40234-1.9492 0.60547-2.9883 0.60547-1.0352 0-2.0391-0.20313-2.9844-0.60547-0.91407-0.38672-1.7344-0.94141-2.4375-1.6484l-0.57032 0.57031c1.5352 1.5391 3.6524 2.4922 5.9922 2.4922 2.3438 0 4.4609-0.95312 5.9961-2.4922"/>
  <path id="path12182" d="m103.18 122.74-0.57032-0.57032c-2.7734 2.7812-6 4.9688-9.5976 6.4922-3.7227 1.582-7.6758 2.3828-11.754 2.3828-4.0742 0-8.0312-0.80078-11.75-2.3828-3.5976-1.5234-6.8281-3.7109-9.5976-6.4922l-0.57032 0.57032c5.6094 5.6289 13.359 9.1094 21.918 9.1094 8.5625 0 16.312-3.4805 21.922-9.1094"/>
  <path id="path12184" d="m127.07 146.71-0.57032-0.57032c-5.875 5.8945-12.715 10.523-20.336 13.754-7.8867 3.3477-16.266 5.0469-24.902 5.0469s-17.012-1.6992-24.898-5.0469c-7.6211-3.2305-14.461-7.8594-20.336-13.754l-0.57032 0.57032c11.723 11.762 27.918 19.035 45.805 19.035 17.891 0 34.086-7.2734 45.809-19.035"/>
  <path id="path12190" d="m108.49 128.07-0.57031-0.57031c-3.4648 3.4727-7.4961 6.1992-11.984 8.1055-4.6484 1.9726-9.582 2.9726-14.676 2.9726-5.0898 0-10.027-1-14.676-2.9726-4.4883-1.9062-8.5195-4.6328-11.98-8.1055l-0.57031 0.57031c6.9688 6.9883 16.594 11.312 27.227 11.312 10.637 0 20.262-4.3242 27.23-11.312"/>
  <path id="path12192" d="m124.41 144.05-0.56641-0.57031c-5.5312 5.5508-11.973 9.9062-19.145 12.949-7.4219 3.1484-15.309 4.75-23.441 4.75-8.1289 0-16.016-1.6016-23.438-4.75-7.1719-3.043-13.613-7.3984-19.145-12.949l-0.56641 0.57031c11.043 11.082 26.297 17.934 43.148 17.934 16.855 0 32.109-6.8516 43.152-17.934"/>
  <path id="path12196" d="m111.14 130.73-0.5664-0.57031c-3.8086 3.8203-8.2422 6.8203-13.18 8.9141-5.1094 2.168-10.539 3.2695-16.137 3.2695-5.5938 0-11.023-1.1016-16.133-3.2695-4.9375-2.0938-9.3711-5.0938-13.18-8.9141l-0.5664 0.57421c7.6484 7.6719 18.211 12.418 29.879 12.418 11.672 0 22.234-4.7461 29.883-12.422"/>
  <path id="path12198" d="m113.8 133.4-0.57031-0.57031c-4.1523 4.1641-8.9883 7.4375-14.371 9.7226-5.5742 2.3633-11.492 3.5625-17.598 3.5625-6.1016 0-12.023-1.1992-17.594-3.5625-5.3828-2.2852-10.219-5.5586-14.371-9.7226l-0.57031 0.57031c8.3281 8.3555 19.832 13.523 32.535 13.523 12.707 0 24.211-5.168 32.539-13.523"/>
  <path id="path12200" d="m116.45 136.06-0.57031-0.57031c-4.4961 4.5117-9.7305 8.0508-15.562 10.527-6.0352 2.5625-12.445 3.8594-19.059 3.8594-6.6094 0-13.02-1.2969-19.055-3.8594-5.832-2.4766-11.066-6.0156-15.566-10.527l-0.56641 0.57031c9.0039 9.0352 21.445 14.625 35.188 14.625 13.746 0 26.187-5.5898 35.191-14.625"/>
  <path id="path12202" d="m121.76 141.38-0.57031-0.57031c-5.1836 5.2031-11.223 9.2891-17.949 12.141-6.9609 2.957-14.355 4.4531-21.98 4.4531-7.6211 0-15.016-1.4961-21.98-4.4531-6.7226-2.8516-12.762-6.9375-17.945-12.141l-0.57031 0.57031c10.363 10.398 24.684 16.832 40.496 16.832 15.816 0 30.137-6.4336 40.5-16.832"/>
  <path id="path12204" d="m119.11 138.72-0.57031-0.57032c-4.8398 4.8555-10.48 8.668-16.758 11.332-6.4961 2.7578-13.402 4.1562-20.52 4.1562-7.1133 0-14.02-1.3984-20.516-4.1562-6.2774-2.6641-11.918-6.4766-16.758-11.332l-0.57031 0.57032c9.6875 9.7148 23.066 15.727 37.844 15.727 14.781 0 28.16-6.0117 37.848-15.727"/>
 </g>
</svg>


  )
}