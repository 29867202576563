import React, {Component, useState} from 'react';



export const LanguageContext = React.createContext();

export default function LanguageProvider(){
    console.log(LanguageContext)

let [context, setContext] =useState("hr")


let change=(event)=>{
    event.preventDefault()
     let newContext =   event.target.name

     setContext(event.target.name)


}


    return <LanguageContext.Provider  value={context}/>

}


