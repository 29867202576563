import React, { Component, useContext, useState } from 'react';
//import { Outlet, Link } from "react-router-dom";
import pageDetails from '../data/cmbPages'
import { LanguageContext } from './LanguageProvider'
import { Link } from "gatsby"
import { Burger, Logo } from './usefulSVGs'


import * as style from '../styles/navbar.module.css'



export default function Navbar(props) {
  const context = useContext(LanguageContext)

  let [drop, setDrop] = useState("up")

  let pDetails = pageDetails[0]

  let toggler = () => {
    drop === "up" ? setDrop("down") : setDrop("up")
    console.log("clicked", drop)
  }


  let toggled;
  drop === "up" ? toggled = "toggleUp" : toggled = "toggleDown";


  let x = (
    <>
      <nav className={style.nav}>

        <Link className={style.logoContainer} to="/">
          <Logo className={style.logo} />
          <p className={style.homeText}>Centre for Mind and Behaviour</p>
        </Link>


        <button className={style.burger} onClick={toggler}><Burger className={style.burger} /></button>
        <ul key={"navlist"} className={`${style.navList} ${style[toggled]}`}>
          <li key={"a"} className={style.homeLink}><Link to="/">{"Home"}</Link></li>
          <li key={"b"} ><Link to="/people">{pDetails.navbar[context].link1}</Link></li>
          <li key={"c"} ><Link to="/news">{pDetails.navbar[context].link2}</Link> </li>
          <li key={"d"} ><Link to="/events">{pDetails.navbar[context].link3}</Link></li>
          <LanguageSwitch change={props.change} />
        </ul>
      </nav>

    </>
  )

  return x
}


function LanguageSwitch({ change }) {
  const context = useContext(LanguageContext)
  let engClass;
  let hrClass;
  context === "en" ? [engClass, hrClass] = ["selectedLang", ""] : [engClass, hrClass] = ["", "selectedLang"]
  let switcher = (
    <>
      <li key={"e"} > <button name="hr" className={`${style[hrClass]} ${style.langSwitch}`} onClick={change}>hr</button></li>
      <li key={"f"} ><button name="en" className={`${style[engClass]} ${style.langSwitch}`} onClick={change}>en</button></li>
    </>
  )

  return switcher
}

