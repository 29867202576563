// extracted by mini-css-extract-plugin
export var burger = "navbar-module--burger--t97bG";
export var homeLink = "navbar-module--homeLink--1kjk+";
export var homeText = "navbar-module--homeText--wQDOp";
export var langSwitch = "navbar-module--langSwitch--otEG4";
export var logo = "navbar-module--logo--JjkE0";
export var logoContainer = "navbar-module--logoContainer--d-akv";
export var nav = "navbar-module--nav--szMI9";
export var navList = "navbar-module--navList--SiNiU";
export var selectedLang = "navbar-module--selectedLang--y5diT";
export var toggleDown = "navbar-module--toggleDown--FtQxo";
export var toggleUp = "navbar-module--toggleUp--wZ49m";